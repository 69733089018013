<template>
  <div>
    <div id="myEcharts" ref="chart"></div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data () {
    return {
    }
  },
  created () {
    setTimeout(() => {
      this.drawEcharts()
    }, 300)
  },
  methods: {
    drawEcharts () {
      const arr = []
      this.data.forEach(item => {
        const obj = {
          name: item.typeName,
          value: item.count
        }
        arr.push(obj)
      })
      // 基于准备好的dom，初始化echarts实例
      const option = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '',
            type: 'pie',
            color: ['#FF8989', '#89B4FF', '#3CC0F1', '#FFD28F', '#93FFCC', '#F76F01', '#01A4F7', '#FE2C8A', '#f36c6c', '#e6cf4e', '#20d180', '#0093ff', '#FF8989', '#89B4FF', '#3CC0F1', '#FFD28F'],
            radius: ['60%', '90%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#1E4A73',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '24',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: arr
          }
        ]
      }
      this.$nextTick(() => {
        const dome = this.$refs.chart
        const myChart = this.$echarts.init(dome)
        // 指定饼图中显示的数据格式
        // 绘制图表
        myChart.setOption(option)
        window.addEventListener('resize', () => { myChart.resize() })
      })
    }
  }
}
</script>

<style lang='scss' scoped>
#myEcharts {
  width: 100%;
  height: 100%;
}
</style>
