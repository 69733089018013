<template>
  <div>
    <div id="myEcharts" ref="chart"></div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data () {
    return {
      count: 0
    }
  },
  created () {
    setTimeout(() => {
      this.drawEcharts()
    }, 300)
  },
  methods: {
    drawEcharts () {
      // 基于准备好的dom，初始化echarts实例
      this.$nextTick(() => {
        const dome = this.$refs.chart
        const myChart = this.$echarts.init(dome)
        // 指定饼图中显示的数据格式
        // 绘制图表
        myChart.setOption({
          color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de'],
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left',
            textStyle: {
              color: '#fff'
            }
          },
          series: [
            {
              name: '工种人员数量',
              type: 'pie',
              radius: '80%',
              data: this.data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        })
        window.addEventListener('resize', () => { myChart.resize() })
      })
    }
  }
}
</script>

<style lang='scss' scoped>
#myEcharts {
  width: 100%;
  height: 100%;
}
</style>
