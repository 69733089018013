<template>
  <div class="chart-container">
    <div class="chart-row">
      <div class="chart-col">
        <div class="chart">
          <div class="content_box">
            <div class="title">工种分类</div>
            <div class="content">
              <jobs-chart id="pipeChart1" :data="jobsList" v-if="jobsList.length > 0" style="padding: 0 20px;box-sizing: border-box;"></jobs-chart>
            </div>
          </div>
        </div>
        <div class="chart">
          <div class="content_box">
            <div class="title">报警趋势统计</div>
            <div class="content">
              <trend-chart id="pipeChart1" :data="jobsList" v-if="jobsList.length > 0"></trend-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chart-row">
      <div class="chart-col">
        <div class="chart">
          <div class="content_box">
            <div class="title">设备分类统计</div>
            <div class="content">
              <device-chart id="pipeChart1" :data="deviceList" v-if="deviceList.length > 0"></device-chart>
            </div>
          </div>
        </div>
        <div class="chart">
          <div class="content_box">
            <div class="title">单位三维图</div>
            <div class="content">
              <video src="../../assets/img/3D.mp4" autoplay muted loop></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import trendChart from '../../components/chart/trendChart'
import jobsChart from '../../components/chart/jobsChart'
import deviceChart from '../../components/chart/deviceChart'
export default {
  components: {
    trendChart,
    jobsChart,
    deviceChart
  },
  data () {
    return {
      jobsList: [],
      deviceList: [],
      alarmList: [],
      countList: []
    }
  },
  created () {
    this.getJobs()
    this.getDevice()
  },
  methods: {
    async getDevice () {
      const { data: result } = await this.$axios.get('/equipment/countByType')
      if (result.code === 200) {
        result.data.pop()
        this.deviceList = result.data
      }
    },
    async getJobs () {
      this.jobsList = [
        {
          name: '保安部',
          value: 20
        },
        {
          name: '市场部',
          value: 5
        },
        {
          name: '运维部',
          value: 10
        },
        {
          name: '宣传部',
          value: 5
        },
        {
          name: '人事部',
          value: 5
        }
      ]
    }
  }
}
</script>

<style lang="scss" scope>
.chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  .chart-row {
    display: flex;
    flex: 1;
  }
  .chart-col {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .chart-col,
  .chart-row {
    .chart {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
      padding: 10px;
      box-sizing: border-box;
      .content_box {
        flex: 3;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        background-color: rgba(30, 74, 115, 0.4);
        border-radius: 20px;
        padding: 10px;
        box-sizing: border-box;
        .title {
          text-align: left;
          color: #01a4f7;
          font-weight: bold;
          font-size: 16px;
          border-left: 4px solid #01a4f7;
          padding-left: 5px;
          box-sizing: border-box;
        }
        .content {
          height: 100%;
          position: relative;
          #pipeChart1 {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: 100%;
          }
          video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
