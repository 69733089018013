<template>
  <div>
    <div id="myEcharts" ref="chart"></div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data () {
    return {
    }
  },
  created () {
    setTimeout(() => {
      this.drawEcharts()
    }, 300)
  },
  methods: {
    drawEcharts () {
      const dataArr = []
      const nameArr = []
      this.data.forEach(item => {
        dataArr.push(item.count)
        nameArr.push(item.typeName)
      })
      // 基于准备好的dom，初始化echarts实例
      const option = {
        roam: false, // 禁止滚轮事件
        tooltip: {
          show: true
        },
        grid: {
          left: 0,
          right: '4%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: {
          data: nameArr,
          axisLabel: {
            textStyle: {
              color: '#fff'
            },
            rotate: 40
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          z: 10
        },
        yAxis: {
          show: false
        },
        series: [
          {
            type: 'bar',
            showBackground: true,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  { offset: 0, color: '#83bff6' },
                  { offset: 0.5, color: '#188df0' },
                  { offset: 1, color: '#188df0' }
                ]
              )
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: '#2378f7' },
                    { offset: 0.7, color: '#2378f7' },
                    { offset: 1, color: '#83bff6' }
                  ]
                )
              }
            },
            data: dataArr,
            label: {
              textStyle: {
                color: '#fff'
              },
              show: true, // 柱子顶部的数值
              position: 'top'
            }
          }
        ]
      }
      this.$nextTick(() => {
        const dome = this.$refs.chart
        const myChart = this.$echarts.init(dome)
        // 指定饼图中显示的数据格式
        // 绘制图表
        myChart.setOption(option)
        window.addEventListener('resize', () => { myChart.resize() })
      })
    }
  }
}
</script>

<style lang='scss' scoped>
#myEcharts {
  width: 100%;
  height: 100%;
}
</style>
